import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import * as Base from 'src/page-styles/base.styled';

import Layout from 'src/Layout';

import { ExternalNews } from 'src/Components/News';
import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';

const NewsWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    display: grid;
    gap: 4rem 2rem;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

interface Props {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          frontmatter: {
            title: string;
            date: string;
            external_image: string;
            url: string;
          };
        };
      }>;
    };
  };
}

function News({ data }: Props): JSX.Element {
  const { allMarkdownRemark } = data;
  const { edges: news } = allMarkdownRemark;

  return (
    <>
      <SEO
        title="In the News | Saber Astronautics"
        description="Scientific method requires you not to rely on a single source. Here's some other sources which can tell you all about us and what we're up to."
        url="https://www.saberastro.com/news"
      />
      <Layout>
        <Container.Dark>
          <Base.MenuGap />
          <Base.Heading>NEWS</Base.Heading>
          <Base.Gap />
          <NewsWrapper>
            {news.map((article) => {
              const { title, date, external_image, url } =
                article.node.frontmatter;

              return (
                <ExternalNews
                  key={title}
                  href={url}
                  title={title}
                  date={date}
                  displayImage={external_image}
                />
              );
            })}
          </NewsWrapper>
        </Container.Dark>
      </Layout>
    </>
  );
}

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query ExternalNewsQuery {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: { frontmatter: { type: { eq: "news" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                date
                external_image
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => <News data={data} />}
  />
);
